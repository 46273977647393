<div class="main-section" id="hero-section">
  <div id="headerWrapper" class="headerWrapper">
    <header id="header">
      <div class="wrapper">
    
        <div id="topNav">
          <nav class="navbar navbar-expand-lg navbar-light p-0 ">
            <div id="logo" >
              <h1 class="logo logo-image">
                <a href="/"
                  ><img
                    src="../../assets/images/ad-logo.png"
                    alt="Nora Schneider "
                /></a>
              </h1>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border-color: #fff;color: #fff;font-size: 25px;">
              <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="#"
                    ><span>HOME</span> <span class="sr-only">(current)</span></a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link"  routerLink="/about" routerLinkActive="active"><span>ABOUT</span></a>
                </li>
                
                <li class="nav-item">
                  <a class="nav-link"  routerLink="/project" routerLinkActive="active"><span>PROJECTS</span> </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#"><span>PRESS</span></a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link"  routerLink="/contact" routerLinkActive="active"><span>CONTACT</span></a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </div>
  
<div id="carouselExampleControls" class="carousel slide carousel-fade" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100  img-fluid image-section" src="../../assets/banner/1.jpg" alt="First slide">
        <div class="section d-flex testimonal-section">
          <figure>
            <blockquote class="double-line">
              “We have been very impressed by the quality and professionalism of interior design and execution of of the office project of Ex Hon. Minister for Public Works Department & BJP president Chandrakant Dada Patil at Mantralaya Mumbai,I was really happy with her designs”   
            </blockquote>
            <figcaption class="">—  Tejas Asher (PWD Maharashtra Government)</figcaption>
          </figure>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid image-section" src="../../assets/banner/2.JPG" alt="Second slide">
        <div class="section d-flex testimonal-section">
          <figure>
            <blockquote class="double-line">
              “We have worked on several projects like residence of MP Vidyadhar Mahale, Landscape Design for MLA Sudhir Mungantiwar and guest house for JNPT at marine drive. I have been extremely happy working with her.”
            </blockquote>
            <figcaption class="">— Tanishq Interiors (PWD Maharashtra Government)</figcaption>
          </figure>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid image-section" src="../../assets/banner/3.jpg" alt="Third slide">
        <div class="section d-flex testimonal-section">
          <figure>
            <blockquote class="double-line">
              “We find The Team design to be very thorough,approachable, quick and efficient.Their integrity towards work is commendable.”
            </blockquote>
            <figcaption class="">— Pushkar Jog (Actor)</figcaption>
          </figure>
        </div>
      </div>
      <div class="carousel-item ">
        <img class="d-block w-100 img-fluid image-section" src="../../assets/banner/4.jpg" alt="Third slide">
        <div class="section d-flex testimonal-section">
          <figure>
            <blockquote class="double-line desktop-display">
              “Our homes reflect the story about us, Jabali and Devanshi transformed that story into a beautifully designed and meaningful space of ours. The understanding and translation of our vision in their design was an easy experience combined with hands-on execution. Overall a very unique experience working with them.”
            </blockquote>
            <blockquote class="double-line mobile-display">
              “Our homes reflect the story about us, Jabali and Devanshi transformed that story into a beautifully designed and meaningful space of ours.”
            </blockquote>
            <figcaption class="">— Prashant Patel (Operations Head-IT)</figcaption>
          </figure>
        </div>
      </div>
      <div class="carousel-item ">
        <img class="d-block w-100 img-fluid image-section" src="../../assets/banner/5.jpg" alt="Third slide">
        <div class="section d-flex testimonal-section">
          <figure>
            <blockquote class="double-line">
              “We can only describe the team design as creative, classy and with a great sense of spacing. They are simply the best in design.”
            </blockquote>
            <figcaption class="">— KC Fabrics</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
