import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { AboutComponent } from "./about/about.component";
import { InteriorsComponent } from "./interiors/interiors.component";
import { ContactComponent } from "./contact/contact.component";
import { ProjectDetailComponent } from "./project-detail/project-detail.component";


const routes: Routes = [
  { path: "", redirectTo: "/index", pathMatch: "full" },
  {
    path: "index",
    component: IndexComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  {
    path: "project",
    component: InteriorsComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },{
    path: "project/:name", 
    component: ProjectDetailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
