import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import data from "./projectImage";
@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent implements OnInit {
  @ViewChild("slickModal", { static: true }) slickModal: SlickCarouselComponent;

  slides: any;
  slideConfig: any;
  routeLinkName: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeLinkName = this.route.snapshot.params["name"];
    this.getImageData();

    this.slideConfig = {
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: null,
      nextArrow:null,
      dots: false,
      infinite: false,
      variableWidth: true,
      draggable: false,
      accessibility: true,
      useCSS:true
    };
  }

  getImageData() {
    data.forEach((x) => {
      if (x.ProjectsName === this.routeLinkName) {
        this.slides = x.section;
      }
    });
  }

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
}
