<div id="footer">
  <div class="footer">
    <div class="container">
      <div class="row line-section">
        <hr />
      </div>
      <div class="row detail-section">
        <div class="col-12 col-md-3 p-0">
          <div class="first-inner-section">
            <p class="company-name pl-0">
              ©2020 The Team And Design<br />
              All rights reserved.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-3 p-0">
          <div class="second-inner-section">
            <p>
              <a target="_blank" href="mailto:info@theteamdesign.com">info@theteamdesign.com</a><br />
              <!-- <strong>T</strong>&nbsp;<a target="_blank" href="tel:+919821915021">+91 98219 15021</a><br />
              <strong>C</strong>&nbsp;<a target="_blank" href="tel:+919819371236">+91 98193 71236</a> -->
            </p>
          </div>
        </div>
        <div class="col-12 col-md-3 p-0">
          <div class="third-inner-section">
            
              TheTeamDesign<br />
              2nd Floor, Vimal
              Plot No. 35,
              N.S. Rd No. 3,
              JVPD Scheme,
              Mumbai 400056<br/>
          
          </div>
        </div>
        <div class="col-12 col-md-3 p-0">
          <div class="forth-inner-section">
            <p class="website-text"> Website by <a target="_blank" href="https://techqueto.com/">Techqueto</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
