let data = [
  
  {
    ProjectsName: "architecture-1",
    section: [
      {
        src: "/assets/Projects/ARCHITECTURE/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/ARCHITECTURE/7.jpg",
        caption: "image",
        class: "box-size",
      }
    ],
  },

  {
    ProjectsName: "club-house-1",
    section: [
      {
        src: "/assets/Projects/CLUB HOUSE/1/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/1/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/1/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/1/4.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "club-house-2",
    section: [
      {
        src: "/assets/Projects/CLUB HOUSE/2/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/5.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/6.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/7.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/8.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/CLUB HOUSE/2/9a.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "commercial-1",
    section: [
      {
        src: "/assets/Projects/COMMERCIAL/c1/1.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c1/2.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c1/3.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c1/4.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c1/5.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c1/6.JPG",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "commercial-2",
    section: [
      {
        src: "/assets/Projects/COMMERCIAL/c2/1.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/2.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/3.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/4.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/5.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/6.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/COMMERCIAL/c2/7.JPG",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "detail",
    section: [
      {
        src: "/assets/Projects/DETAIL/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/5.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/6.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/7.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/8.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/DETAIL/9.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "hotel-1",
    section: [
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/5.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/6.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-1/7.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "hotel-2",
    section: [
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-2/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-2/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-2/3.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "hotel-3",
    section: [
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-3/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-3/2.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "hotel-4",
    section: [
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-4/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-4/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-4/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-4/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/HOTEL-4/5.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "restaurant",
    section: [
      {
        src: "/assets/Projects/HOSPITALITY/RESTAURANT/1.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/RESTAURANT/2.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/HOSPITALITY/RESTAURANT/3.JPG",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "office-1",
    section: [
      {
        src: "/assets/Projects/OFFICE/OFFICE-1/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-1/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-1/4.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "office-2",
    section: [
      {
        src: "/assets/Projects/OFFICE/OFFICE-2/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-2/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-2/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-2/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-2/5.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "office-3",
    section: [
      {
        src: "/assets/Projects/OFFICE/OFFICE-3/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-3/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-3/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/OFFICE/OFFICE-3/4.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "residential-1",
    section: [
      {
        src: "/assets/Projects/RESIDENTIAL/r1/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r1/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r1/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r1/4.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "residential-2",
    section: [
      {
        src: "/assets/Projects/RESIDENTIAL/r2/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r2/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r2/3.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r2/4.JPG",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "residential-3",
    section: [
      {
        src: "/assets/Projects/RESIDENTIAL/r3/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r3/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r3/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r3/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r3/5.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r3/6.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },{
    ProjectsName: "residential-4",
    section: [
      {
        src: "/assets/Projects/RESIDENTIAL/r4/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/5.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/6.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/7.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/8.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/r4/9.jpg",
        caption: "image",
        class: "box-size",
      },
    ],
  },
  {
    ProjectsName: "villa",
    section: [
      {
        src: "/assets/Projects/RESIDENTIAL/VILLA/1.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/VILLA/2.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/VILLA/3.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RESIDENTIAL/VILLA/4.JPG",
        caption: "image",
        class: "box-size",
      }
    ],
  },
  {
    ProjectsName: "travel-agency-1",
    section: [
      {
        src: "/assets/Projects/AGENCY/AGENCY-1/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/AGENCY/AGENCY-1/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/AGENCY/AGENCY-1/3.jpg",
        caption: "image",
        class: "box-size",
      }
    ],
  },
  {
    ProjectsName: "travel-agency-2",
    section: [
      {
        src: "/assets/Projects/AGENCY/AGENCY-2/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/AGENCY/AGENCY-2/2.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/AGENCY/AGENCY-2/3.jpg",
        caption: "image",
        class: "box-size",
      }
    ],
  },
  {
    ProjectsName: "travel-agency-3",
    section: [
      {
        src: "/assets/Projects/AGENCY/AGENCY-3/1.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/AGENCY/AGENCY-3/2.jpg",
        caption: "image",
        class: "box-size",
      }
    ],
  },  {
    ProjectsName: "retail-1",
    section: [
      {
        src: "/assets/Projects/RETAIL/1.JPG",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RETAIL/2.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RETAIL/3.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RETAIL/4.jpg",
        caption: "image",
        class: "box-size",
      },
      {
        src: "/assets/Projects/RETAIL/5.jpg",
        caption: "image",
        class: "box-size",
      }
    ],
  },
];

export default data;
