

 
<div class="main-section">
  <app-header></app-header>
 <section class="pageWrapper">

  <div >
    <div class="row">
  <ngx-slick-carousel class="carousel " #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <a  href="{{slide.src}}" data-lightbox="project-image">
            <img class="ui bordered small image img-fluid {{slide.class}}" src="{{slide.src}}"  />
          </a> 
    </div>
  </ngx-slick-carousel>
  <div class="arrow-wrapper-left left guide"> <a (click)="prev()" ><i class="fa  fa-angle-left" ></i></a></div>
  <div class="arrow-wrapper-right right guide"> <a (click)="next()"><i class="fa  fa-angle-right" ></i></a></div>

</div>
</div>
  </section>

</div>
<script>
  lightbox.option({
    showImageNumberLabel:false,
    resizeDuration: 500,
    wrapAround: true,
   
  })
</script>
