import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { AboutComponent } from "./about/about.component";
import { IndexComponent } from "./index/index.component";
import { InteriorsComponent } from "./interiors/interiors.component";
import { FooterComponent } from "./footer/footer.component";
import { ContactComponent } from "./contact/contact.component";
import { ProjectDetailComponent } from "./project-detail/project-detail.component";
import { SlickCarouselModule } from "ngx-slick-carousel";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    IndexComponent,
    InteriorsComponent,
    FooterComponent,
    ContactComponent,
    ProjectDetailComponent,
    
  ],
  imports: [BrowserModule, AppRoutingModule, SlickCarouselModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
