<div class="main-section">
  <app-header></app-header>
  <section class="pageWrapper">
    <!--  desktop section -->
    <div class="container d-none d-lg-block">
      <div class="row">
         <div class="col-md-8 col-12 p-0">
          <div class="section d-flex testimonal-section">
            <img
              class="section w-100 img-fluid"
              src="../../assets/about-us/pic-1.jpg"
            />
          </div>
        </div>
  
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex">
            
            <p class="msg-text">
              Design Philosophy <br/>
              Daniel Gilbert said “Change isn’t simply an important aspect of life- it’s life itself. The mind and the body of a person is changing every second of everyday “, and with that the need to constantly change and evolve architectural and interior spaces. We believe that designs have to be constantly moving and adapting to times and people. As designers our effort is to build flexible spaces to anticipate and support this change.
             </p>
          </div>
        </div>
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex">
            
            <p class="msg-text">
              With Jabali and Devanshi’s expertise and vast knowledge of construction and design for two decades, they have used their know how for providing professional PMC of interiors to developers and clients for multi-storeyed residential projects and common amenities for residential and commercial buildings and private residences.
              <br>
              Their belief to explore and think out-of-the box adds dynamism and uniqueness to the projects.
            </p>
          </div>
     
        </div>
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex">
            <img
            class="section img-fluid"
            src="../../assets/about-us/pic-2.jpg"
          />
          </div>
        </div>
        
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “Architecture is really about well-being .I think that people want to feel good in a space.”
              </blockquote>
              <figcaption class="source">— Zaha Hadid</figcaption>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex">
            <img
            class="section img-fluid"
            src="../../assets/about-us/pic-3.jpg"
          />
          </div>
        </div>
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “The details are not the details.They make the design.”
              </blockquote>
              <figcaption class="source">— Charles Eames</figcaption>
            </figure>
          </div>
        </div>
          <div class="col-md-4 col-12 p-0">
            <div class="section d-flex"> 
              <p class="msg-text">
                Design is not just about creating good looking spaces.<br>
                As designers it is our constant effort not only to make beautiful spaces but also be responsible to create spaces that are practical. Everything should be functional and add value to the space

                </p>
              </div>
        
        </div>
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex"> 
            <p class="msg-text">
              The less is more (austere) sensibilities is reflected in the modern designs-from open floor plans to simple line furnishings and painted accents like beiges, grays and ascetic hues. 
              Thus creating a neat, fluid and relaxing environment.Streamlined furniture gives you more space, even in the smallest rooms. Plus, a calm palette or limited color choice makes a room feel serene and less chaotic. Finally, less of everything means more money in your pocket.

             </p>
            </div>
        </div>
        <div class="col-md-8 col-12 p-0">
          <div class="section d-flex testimonal-section">
            <img
              class="section w-100 img-fluid"
              src="../../assets/about-us/pic-4.jpg"
            />
          </div>
        </div>
        <div class="col-md-4 col-12 p-0">
          <img
          class="first-section img-fluid"
          src="../../assets/about-us/pic-5.jpg"
        />
      </div>
      
        <div class="col-md-4 col-12 p-0">
          <!-- <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “A room should never allow the eye to settle in one place.It should smile at you and create fantasy.”
              </blockquote>
            </figure>
          </div> -->
      
          <div class="section d-flex">
            <img
              class="section img-fluid"
              src="../../assets/about-us/text-5.jpg"
            />
          </div>
          <div class="section d-flex">
            <img
              class="section img-fluid"
              src="../../assets/about-us/pic-6.jpg"
            />
          </div>
        </div>
      

        <div class="col-md-4 col-12 p-0">
          
  
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “The best rooms have something to say about people who live in them.”
              </blockquote>
              <figcaption class="source">— David Hicks</figcaption>
            </figure>
          </div>

          <div class="section d-flex">
            <p class="msg-text">
              Great design is more than just good aesthetics. It is the way we use objects. It’s only then that you experience all the subtle touches, all the things visible and invisible that make great design
             </p>
          </div>
        </div>



        
        <div class="col-md-4 col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “Real comfort, visual and physical, is vital to every room.”
              </blockquote>
              <figcaption class="source">— Mark  Hampton</figcaption>
            </figure>
          </div>
        </div>
        <div class="col-md-8 col-12 p-0 ">
      
          <div class="section d-flex">
            <img
            class="section img-fluid w-100"
            src="../../assets/about-us/pic-7.png"
          />
          </div>
        </div>


      </div>
    </div>

<!-- mobile section -->
    <div class="container d-block d-lg-none">
      <div class="row">
         <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <img
              class="section w-100 img-fluid"
              src="../../assets/about-us/mobile-pic-1.jpg"
            />
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <img
              class="section w-100 img-fluid"
              src="../../assets/about-us/mobile-pic-2.jpg"
            />
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex">
            
            <p class="msg-text">
              With Jabali and Devanshi’s expertise and vast knowledge of construction and design for two decades, they have used their know how for providing professional PMC of interiors to developers and clients for multi-storeyed residential projects and common amenities for residential and commercial buildings and private residences.
              <br>
              Their belief to explore and think out-of-the box adds dynamism and uniqueness to the projects.
            </p>
          </div>
     
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex justify-content-center">
            <img
            class="section img-fluid"
            src="../../assets/about-us/pic-2.jpg"
          />
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex">
            
            <p class="msg-text">
              Design Philosophy <br/>
              Daniel Gilbert said “Change isn’t simply an important aspect of life- it’s life itself. The mind and the body of a person is changing every second of everyday “, and with that the need to constantly change and evolve architectural and interior spaces. We believe that designs have to be constantly moving and adapting to times and people. As designers our effort is to build flexible spaces to anticipate and support this change.
             </p>
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “Architecture is really about well-being .I think that people want to feel good in a space.”
              </blockquote>
              <figcaption class="source">— Zaha Hadid</figcaption>
            </figure>
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex justify-content-center">
            <img
            class="section img-fluid"
            src="../../assets/about-us/pic-3.jpg"
          />
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “The details are not the details.They make the design.”
              </blockquote>
              <figcaption class="source">— Charles Eames</figcaption>
            </figure>
          </div>
        </div>
          <div class=" col-12 p-0">
            <div class="section d-flex"> 
              <p class="msg-text">
                Design is not just about creating good looking spaces.<br>
                As designers it is our constant effort not only to make beautiful spaces but also be responsible to create spaces that are practical. Everything should be functional and add value to the space

                </p>
              </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <img
              class="section w-100 img-fluid"
              src="../../assets/about-us/pic-4-mobile.jpg"
            />
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex"> 
            <p class="msg-text">
              The less is more (austere) sensibilities is reflected in the modern designs-from open floor plans to simple line furnishings and painted accents like beiges, grays and ascetic hues. 
              Thus creating a neat, fluid and relaxing environment.Streamlined furniture gives you more space, even in the smallest rooms. Plus, a calm palette or limited color choice makes a room feel serene and less chaotic. Finally, less of everything means more money in your pocket.

             </p>
            </div>
        </div>
<!--    
        <div class=" col-12 p-0">
          <img
          class="first-section img-fluid"
          src="../../assets/about-us/pic-5.jpg"
        />
      </div> -->

        <div class=" col-12 p-0">
      
          <div class="section d-flex justify-content-center">
            <img
              class="section img-fluid"
              src="../../assets/about-us/text-5.jpg"
            />
          </div>
        </div>
        
        <div class=" col-12 p-0">
          
  
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “The best rooms have something to say about people who live in them.”
              </blockquote>
              <figcaption class="source">— David Hicks</figcaption>
            </figure>
          </div>
          <div class=" col-12 p-0">
          <div class="section d-flex justify-content-center">
            <img
              class="section img-fluid"
              src="../../assets/about-us/pic-6.jpg"
            />
          </div>
        </div>
          <div class="section d-flex">
            <p class="msg-text">
              Great design is more than just good aesthetics. It is the way we use objects. It’s only then that you experience all the subtle touches, all the things visible and invisible that make great design
             </p>
          </div>
        </div>
        <div class=" col-12 p-0">
          <div class="section d-flex testimonal-section">
            <figure>
              <blockquote>
                “Real comfort, visual and physical, is vital to every room.”
              </blockquote>
              <figcaption class="source">— Mark  Hampton</figcaption>
            </figure>
          </div>
        </div>
        <div class=" col-12 p-0 ">
      
          <div class="section d-flex justify-content-center">
            <img
            class="section img-fluid w-100"
            src="../../assets/about-us/pic-7-mobile.jpg"
          />
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
