<div class="main-section">
  <app-header></app-header>
  <section class="pageWrapper">
    <div class="container">
      <div class="row">
        <div class="col-12 p-0">
          <h1 class="header-text m-0">
            Projects
          </h1>
         
        </div>
        <!--  Architecture Projects -->
        <div class="Projects-name  col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Architecture Projects
          </h3>
          <button (click)="showArchitecture=!showArchitecture">Show/Hide</button>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
           
              <a class="nav-link" routerLink="/project/architecture-1" routerLinkActive="active">
                <div class="overlay">
                <i class="fa fa-plus-square" aria-hidden="true"></i>
              </div>
                <img class="small-image-block-wrapper img-fluid  " src="../../assets/projectPage/ARCHITECTURE/1.jpg" />
              </a>
              
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/ARCHITECTURE/2.jpg" />
            </div>
          </div>
        </div>
  
    
        <div class="card-section" *ngIf="showArchitecture">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/ARCHITECTURE/3.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showArchitecture">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/ARCHITECTURE/4.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showArchitecture">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/ARCHITECTURE/5.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showArchitecture">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/ARCHITECTURE/6.jpg" />
            </div>
          </div>
        </div>
     
        <!-- Club House Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Club House Projects
          </h3>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/club-house-1" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/CLUB HOUSE/1/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/club-house-2" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/CLUB HOUSE/2/1.jpg" /></a>
            </div>
          </div>
        </div>

        <!--  Commercial Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Commercial Projects
          </h3>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/commercial-1" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/COMMERCIAL/c1/2.JPG" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/commercial-2" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/COMMERCIAL/c2/1.JPG" /></a>
            </div>
          </div>
        </div>


        <!-- Hospitality Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Hospitality Projects
          </h3>
          <button (click)="showHospitality=!showHospitality">Show/Hide</button>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/hotel-1" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/HOSPITALITY/HOTEL-1/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/hotel-2" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/HOSPITALITY/HOTEL-2/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf= "showHospitality">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/hotel-3" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/HOSPITALITY/HOTEL-3/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf= "showHospitality">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/hotel-4" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/HOSPITALITY/HOTEL-4/1.jpg" /></a>
            </div>
          </div>
        </div>
        <!-- Restaurant Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Restaurant Projects
          </h3>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/restaurant" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid "
                  src="../../assets/projectPage/HOSPITALITY/RESTAURANT/1.JPG" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <img class="small-image-block-wrapper img-fluid "
                src="../../assets/projectPage/HOSPITALITY/RESTAURANT 2/RESTAURANT.jpg" />
            </div>
          </div>
        </div>

        <!-- Landscape  Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Landscape Projects
          </h3>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/LANDSCAPE/1.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/LANDSCAPE/2.jpg" />
            </div>
          </div>
        </div>

        <!-- Office Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Office Projects
          </h3>
          <button (click)="showOffice=!showOffice">Show/Hide</button>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/office-1" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/OFFICE/OFFICE-1/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/office-2" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/OFFICE/OFFICE-2/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showOffice">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/office-3" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/OFFICE/OFFICE-3/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showOffice">
          <div class="block-section">
            <div class="image-section big-image">
              <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/OFFICE/OFFICE-4/office-4.JPG" />
            </div>
          </div>
        </div>

        <!-- Residential Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Residential Projects
          </h3>
          <button (click)="showResidential=!showResidential">Show/Hide</button>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/residential-4" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/RESIDENTIAL/r4/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section">
            <div class="block-section">
              <div class="image-section big-image">
                <a class="nav-link" routerLink="/project/villa" routerLinkActive="active">
                  <div class="overlay">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </div>
                  <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/RESIDENTIAL/VILLA/1.JPG" /></a>
              </div>
            </div>
          </div>
        <div class="card-section" *ngIf="showResidential">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/residential-1" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/RESIDENTIAL/r1/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section"  *ngIf="showResidential">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/residential-2" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/RESIDENTIAL/r2/1.jpg" /></a>
            </div>
          </div>
        </div>
        <div class="card-section"  *ngIf="showResidential">
          <div class="block-section">
            <div class="image-section big-image">
              <a class="nav-link" routerLink="/project/residential-3" routerLinkActive="active">
                <div class="overlay">
                  <i class="fa fa-plus-square" aria-hidden="true"></i>
                </div>
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/RESIDENTIAL/r3/1.jpg" /></a>
            </div>
          </div>
        </div>
  

          <!-- Retail Projects -->
          <div class="Projects-name col-12 py-3">
            <h3 class="pl-3 text-white m-0">
              Retail Projects
            </h3>
          </div>
          <div class="card-section">
            <div class="block-section">
              <div class="image-section big-image">
                <a class="nav-link" routerLink="/project/retail-1" routerLinkActive="active">
                  <div class="overlay">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </div>
                  <img class="small-image-block-wrapper img-fluid" src="../../assets/projectPage/RETAIL/1.JPG" />
                  </a>
              </div>
            </div>
          </div>
          <div class="card-section">
            <div class="block-section">
              <div class="image-section big-image">
               
                  <img class="small-image-block-wrapper img-fluid" src="../../assets/projectPage/RETAIL/2.jpg" />
                 
              </div>
            </div>
          </div>

          <!-- Travel Agency -->
          <div class="Projects-name col-12 py-3">
            <h3 class="pl-3 text-white m-0">
              Travel Agency Projects
            </h3>
            <button (click)="showTravel=!showTravel">Show/Hide</button>
          </div>
          <div class="card-section">
            <div class="block-section">
              <div class="image-section big-image">
                <a class="nav-link" routerLink="/project/travel-agency-1" routerLinkActive="active">
                  <div class="overlay">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </div>
                  <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/TRAVLE AGENCY/TRAVEL AGENCY-1/1.jpg" /></a>
              </div>
            </div>
          </div>
          <div class="card-section">
            <div class="block-section">
              <div class="image-section big-image">
                <a class="nav-link" routerLink="/project/travel-agency-2" routerLinkActive="active">
                  <div class="overlay">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </div>
                  <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/TRAVLE AGENCY/TRAVEL AGENCY-2/1.jpg" /></a>
              </div>
            </div>
          </div>
          <div class="card-section" *ngIf="showTravel">
            <div class="block-section">
              <div class="image-section big-image">
                <a class="nav-link" routerLink="/project/travel-agency-3" routerLinkActive="active">
                  <div class="overlay">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </div>
                  <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/TRAVLE AGENCY/TRAVEL AGENCY-3/1.jpg" /></a>
              </div>
            </div>
          </div>



                  <!-- Detail Projects -->
        <div class="Projects-name col-12 py-3">
          <h3 class="pl-3 text-white m-0">
            Mood Board
          </h3>
          <button (click)="showDetail=!showDetail">Show/Hide</button>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/1.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/2.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showDetail">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/3.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showDetail">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/4.jpg" />
            </div>
          </div>
        </div>

        <div class="card-section" *ngIf="showDetail">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/6.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showDetail">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper img-fluid " src="../../assets/projectPage/DETAIL/7.jpg" />
            </div>
          </div>
        </div>
        <div class="card-section" *ngIf="showDetail">
          <div class="block-section">
            <div class="image-section big-image">
                <img class="small-image-block-wrapper " src="../../assets/projectPage/DETAIL/8.jpg" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
