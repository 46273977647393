import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interiors',
  templateUrl: './interiors.component.html',
  styleUrls: ['./interiors.component.scss']
})
export class InteriorsComponent implements OnInit {

  showArchitecture : boolean = false;
  showHospitality :boolean  = false;
  showOffice :boolean  = false;
  showResidential :boolean  = false;
  showTravel :boolean  = false;
  showDetail :boolean  = false;
  constructor() {

   }

  ngOnInit(): void {
  }






}
