<div id="headerWrapper" class="headerWrapper">
  <header id="header">
    <div class="wrapper">
  
      <div id="topNav">
        <nav class="navbar navbar-expand-lg navbar-light ">
          <div id="logo" >
            <h1 class="logo logo-image">
              <a href="/"
                ><img
                  src="../../assets/images/ad-logo.png"
                  alt="Nora Schneider "
              /></a>
            </h1>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border-color: #fff;color: #fff;font-size: 25px;">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" href="#"
                  ><span>HOME</span> <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link"  routerLink="/about" routerLinkActive="active"><span>ABOUT</span></a>
              </li>
              
              <li class="nav-item">
                <a class="nav-link"  routerLink="/project" routerLinkActive="active"><span>PROJECTS</span> </a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#"><span>PRESS</span></a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link"  routerLink="/contact" routerLinkActive="active"><span>CONTACT</span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</div>
